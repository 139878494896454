export function useWageFormatting(job: Position) {
   function formatWage(wage?: string) {
      if (!wage) return '';

      return new Intl.NumberFormat('nl-NL', { style: 'decimal' }).format(+wage);
   }

   const wageMinimum = computed(() => job.wage_minimum);
   const wageMaximum = computed(() => job.wage_maximum);

   const wage = computed(() => {
      if (wageMinimum.value && wageMaximum.value && wageMinimum.value !== wageMaximum.value) {
         return `${formatWage(wageMinimum.value)} - ${formatWage(wageMaximum.value)}`;
      }
      if (wageMinimum.value) {
         return formatWage(wageMinimum.value);
      }
      if (wageMaximum.value) {
         return formatWage(wageMaximum.value);
      }
      return '';
   });

   return { wage };
}
